<template>
  <v-container fluid>
    <v-card tile>

      <v-card-title>
        Registrations
        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon color="green" @click="confirmRegistration(item.id)" class="mr-2">mdi-check-circle-outline</v-icon>

          <v-icon class="mr-2" color="blue" @click="editItem(item.id)">mdi-pencil</v-icon>
          <v-icon color="red" @click="openDeleteItemDialog(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="deleteItemDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteItemDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
export default {
  data () {
    return {
      path: 'registrations',
      items: [],
      item: null,
      deleteItemDialog: false,
      loading: true,
      search: '',
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'SURNAME', value: 'surname' },
        { text: 'E-MAIL', value: 'email' },
        { text: 'DATE OF BIRTH', value: 'dateOfBirth' },
        { text: 'PLACE OF BIRTH', value: 'placeOfBirth' },
        { text: 'PERMANENT ADDRESS', value: 'permanentAddress' },
        { text: 'REGISTRATION DATETIME', value: 'registrationDatetime' },
        { text: 'ROOM NUMBER', value: 'roomNumber' },
        { text: 'ACTIONS', value: 'actions', sortable: false, width: '150px' }
      ]
    }
  },

  methods: {
    editItem (id) {
      this.$router.push(`/admin/${this.path}/edit?id=${id}`)
    },
    async fetchItems () {
      this.items = await this.$store.dispatch('rest/fetchAllItems', { path: this.path })
    },
    confirmRegistration (id) {
      console.log(id)
      this.$router.push(`/admin/users/new?registration_id=${id}`)
    },
    async deleteItem () {
      await this.$store.dispatch('rest/deleteItem', {
        path: this.path,
        id: this.item.id
      })
      this.items.splice(this.items.indexOf(this.item), 1)
      this.item = null
      this.deleteItemDialog = false
    },
    openDeleteItemDialog (item) {
      this.item = item
      this.deleteItemDialog = true
    }
  },

  async mounted () {
    document.title = 'Registrations | Masarka Student Club'
    this.loading = true
    await this.fetchItems()
    this.loading = false
  }
}
</script>

<style scoped>

</style>
